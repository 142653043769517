// extracted by mini-css-extract-plugin
export var navbarOpen = "PurchaseProcessBlock-module--navbar-open--2Lju7";
export var isModal = "PurchaseProcessBlock-module--isModal--P1EjM";
export var isLoaded = "PurchaseProcessBlock-module--is-loaded--puvfn";
export var Plx = "PurchaseProcessBlock-module--Plx--3q0PZ";
export var customTooltip = "PurchaseProcessBlock-module--customTooltip--FCrql";
export var calculatorContainer__wrapper = "PurchaseProcessBlock-module--calculatorContainer__wrapper--1Ubqk";
export var posRelative = "PurchaseProcessBlock-module--posRelative--eVVFa";
export var calculatorSection = "PurchaseProcessBlock-module--calculator-section--1uZG5";
export var refiAdviceSection = "PurchaseProcessBlock-module--refi-advice-section--3Dls_";
export var faqSection = "PurchaseProcessBlock-module--faq-section--1WEjR";
export var glossarySection = "PurchaseProcessBlock-module--glossary-section--3loeq";
export var homeBuyAdvice = "PurchaseProcessBlock-module--home-buy-advice--BPoWj";
export var ourBeliefs = "PurchaseProcessBlock-module--our-beliefs--2nv41";
export var meetTeam = "PurchaseProcessBlock-module--meet-team--1s-8v";
export var aboutcake = "PurchaseProcessBlock-module--aboutcake--HikpS";
export var ourMission = "PurchaseProcessBlock-module--our-mission--3jSsE";
export var ourStory = "PurchaseProcessBlock-module--our-story--1Dfmo";
export var mainWrapper = "PurchaseProcessBlock-module--main-wrapper--lpfUo";
export var btn = "PurchaseProcessBlock-module--btn--k1_fS";
export var iconWrapper = "PurchaseProcessBlock-module--icon-wrapper--LLK3D";
export var small = "PurchaseProcessBlock-module--small--TwH5C";
export var green = "PurchaseProcessBlock-module--green--1GuzQ";
export var greenFill = "PurchaseProcessBlock-module--green--fill--36RLg";
export var greenBorder = "PurchaseProcessBlock-module--green--border--3MoE3";
export var dark = "PurchaseProcessBlock-module--dark--33Zt9";
export var darkLg = "PurchaseProcessBlock-module--dark--lg--Ffdqf";
export var p1 = "PurchaseProcessBlock-module--p1--j-X8r";
export var p2 = "PurchaseProcessBlock-module--p2--2q0N0";
export var centerAlign = "PurchaseProcessBlock-module--center-align--C72UP";
export var leftAlign = "PurchaseProcessBlock-module--left-align--1xI7r";
export var section = "PurchaseProcessBlock-module--section--2t9Po";
export var contentsWrap = "PurchaseProcessBlock-module--contents-wrap--37EAq";
export var lDesktop = "PurchaseProcessBlock-module--l-desktop--2OtbR";
export var lMobile = "PurchaseProcessBlock-module--l-mobile--2Nkxr";
export var sectionHead = "PurchaseProcessBlock-module--section-head--17yOg";
export var hidMob = "PurchaseProcessBlock-module--hid-mob--36HfE";
export var hidDsktp = "PurchaseProcessBlock-module--hid-dsktp--3rwjq";
export var CakeFormWrap = "PurchaseProcessBlock-module--CakeFormWrap--Cm9Ph";
export var CakeFieldWrap = "PurchaseProcessBlock-module--CakeFieldWrap--X-XeL";
export var inputDollarWrap = "PurchaseProcessBlock-module--input--dollar-wrap--1NmjZ";
export var customRange = "PurchaseProcessBlock-module--custom-range--1J_k7";
export var customRange__track = "PurchaseProcessBlock-module--custom-range__track--3Kc1B";
export var customRange__thumb = "PurchaseProcessBlock-module--custom-range__thumb--30oLP";
export var thumbValue = "PurchaseProcessBlock-module--thumb-value--2NpsP";
export var container = "PurchaseProcessBlock-module--container--3ExgN";
export var containerSm = "PurchaseProcessBlock-module--container--sm--AOPSb";
export var cake404 = "PurchaseProcessBlock-module--cake404--1Uu39";
export var cake404__wrapper = "PurchaseProcessBlock-module--cake404__wrapper--1WTQM";
export var cake404__figure = "PurchaseProcessBlock-module--cake404__figure--11Gco";
export var cake404__contents = "PurchaseProcessBlock-module--cake404__contents--1CLNY";
export var contents__top = "PurchaseProcessBlock-module--contents__top--3ivJh";
export var contents__bottom = "PurchaseProcessBlock-module--contents__bottom--2yst1";
export var footIcon = "PurchaseProcessBlock-module--footIcon--JBQDU";
export var genericSection = "PurchaseProcessBlock-module--generic-section--1hnFW";
export var titleSection = "PurchaseProcessBlock-module--title-section--3qiT5";
export var PersonalizeModal = "PurchaseProcessBlock-module--PersonalizeModal--1jAaM";
export var onetrustConsentSdk = "PurchaseProcessBlock-module--onetrust-consent-sdk--Qh7yg";
export var onetrustBannerSdk = "PurchaseProcessBlock-module--onetrust-banner-sdk--2wQEB";
export var onetrustButtonGroup = "PurchaseProcessBlock-module--onetrust-button-group--x940y";
export var onetrustPcDarkFilter = "PurchaseProcessBlock-module--onetrust-pc-dark-filter--1AI0N";
export var onetrustPcSdk = "PurchaseProcessBlock-module--onetrust-pc-sdk--2jVf3";
export var otSdkBtnFloating = "PurchaseProcessBlock-module--ot-sdk-btn-floating--1SM5x";
export var calculatorContainer = "PurchaseProcessBlock-module--calculatorContainer--1KEMH";
export var dsktRes = "PurchaseProcessBlock-module--dskt-res--29lyF";
export var disBtn = "PurchaseProcessBlock-module--dis-btn--1p2A9";
export var otFloatingButton__front = "PurchaseProcessBlock-module--ot-floating-button__front--2hg5_";
export var otFloatingButton__back = "PurchaseProcessBlock-module--ot-floating-button__back--JrFJl";
export var Article__wrapper = "PurchaseProcessBlock-module--Article__wrapper--2tMm9";
export var ArticleBanner = "PurchaseProcessBlock-module--Article-banner--2W814";
export var ArticleBanner__top = "PurchaseProcessBlock-module--Article-banner__top--1sp6C";
export var ArticleBanner__title = "PurchaseProcessBlock-module--Article-banner__title--IUKHM";
export var ArticleBanner__readTime = "PurchaseProcessBlock-module--Article-banner__read-time--272dZ";
export var ArticleBanner__image = "PurchaseProcessBlock-module--Article-banner__image--328Ky";
export var ArticleBanner__bottom = "PurchaseProcessBlock-module--Article-banner__bottom--23Abo";
export var ArticleBanner__subtitle = "PurchaseProcessBlock-module--Article-banner__subtitle--3Ra0a";
export var ArticleContents = "PurchaseProcessBlock-module--Article-contents--1K1_1";
export var Article__sources = "PurchaseProcessBlock-module--Article__sources--2Kvh1";
export var perspectiveWrap = "PurchaseProcessBlock-module--perspectiveWrap--3UOE_";
export var PurchaseProcessBlock = "PurchaseProcessBlock-module--PurchaseProcessBlock--2soS4";